import React from "react";
import "./testimonials.css";
import { Pagination, Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import r1 from "../../img/reviews/r1.jpg";
import r2 from "../../img/reviews/r2.jpg";
import r3 from "../../img/reviews/r3.jpg";
import r4 from "../../img/reviews/r4.jpg";
import r5 from "../../img/reviews/r5.jpg";
import review from "../../img/reviews/re.png";
import { themeContext } from "../../Context/Context";
import { useContext } from "react";

const Testimonials = () => {
  const theme = useContext(themeContext);
  const darkMode = theme.state.darkMode;

  const clients = [
    {
      img: r1,
      review:
        "Outstanding work! The team exceeded our expectations and delivered a top-notch product. Their innovative approach and attention to detail were remarkable. Communication was seamless and they were always available to address our concerns. We are thrilled with the results and look forward to future collaborations. Highly recommended!",
    },
    {
      img: r2,
      review:
        "Their attention to detail and commitment to our project was impressive. The team went above and beyond to ensure everything was perfect. They were professional, reliable, and dedicated to delivering the best possible outcome. The final product has significantly improved our business operations. good work, keep it up!",
    },
    {
      img: r3,
      review:
        "Professional and reliable. The final product was exactly what we needed for our business. Their expertise and innovative solutions made a huge difference. They kept us updated throughout the process and delivered on time. We couldn't be happier with their work and look forward to working with them again. Exceptional service!",
    },
    {
      img: r4,
      review:
        "Great communication throughout the project. The results speak for themselves. The team was responsive, attentive, and committed to our satisfaction. Their creative solutions and attention to detail were exactly what we needed. We are extremely satisfied with the final product and highly recommend their services. Outstanding experience!",
    },
    {
      img: r5,
      review:
        "Innovative solutions and exceptional service. We couldn't be happier with the outcome. The team took the time to understand our needs and delivered a product that exceeded our expectations. Their dedication, professionalism, and expertise were evident in every aspect of the project. We highly recommend and look forward to future projects!",
    },
  ];

  return (
    <div className="t-wrapper">
      <div className="t-heading">
        <span style={{ color: darkMode ? "white" : "" }}>
          Latest Client Feedback on My Services
        </span>
        <img src={review} alt="" />
      </div>
      {/* Swiper component for the carousel */}
      <Swiper
        pagination={{ clickable: true }}
        autoplay={{
          delay: 3000, // Delay between slides
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
        }}
        loop={true}
        speed={800}
        modules={[Pagination, Autoplay]}
        slidesPerView={1}
      >
        {clients.map((client, index) => (
          <SwiperSlide key={index}>
            <div
              className="testimonial"
              style={{
                backgroundColor: darkMode ? "#273740" : "rgb(199, 199, 199)",
              }}
            >
              {/* <div
                className="blur t-blur1"
                style={{ background: "var(--purple)" }}
              ></div>
              <div
                className="blur t-blur2"
                style={{ background: "var(--skyblue)" }}
              ></div> */}
              <img src={client.img} alt="" />
              <span style={{ color: darkMode ? "white" : "" }}>
                {client.review}
              </span>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default Testimonials;
