import React from "react";
import "./footerModal.css";
import PrivacyPolicy from './PrivacyPolicy';
import TermsOfService from './TermsOfService';
import CookiesPolicy from './CookiesPolicy';

const FooterModal = ({ policyType, onClose }) => {
    const renderContent = () => {
        switch (policyType) {
          case 'Privacy Policy Text':
            return <PrivacyPolicy />;
          case 'Terms of Service Text':
            return <TermsOfService />;
          case 'Cookies Policy Text':
            return <CookiesPolicy />;
          default:
            return null;
        }
      };
    
      return (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={onClose}>&times;</span>
            <div className="footer-modal-text">
              {renderContent()}
            </div>
          </div>
        </div>
      );
    };
    
    export default FooterModal;
