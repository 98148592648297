import React from "react";

const CookiesPolicy = () => (
  <div>
    <h3>Cookies Policy</h3>
    <p>
      Our website uses cookies to enhance your experience. This Cookies Policy
      explains what cookies are, how we use cookies, how third-parties we may
      partner with may use cookies on the Service, your choices regarding
      cookies, and further information about cookies.
    </p>
    <h4>What Are Cookies</h4>
    <p>
      Cookies are small text files that are used to store small pieces of
      information. They are stored on your device when the website is loaded on
      your browser. Cookies help us improve your experience by remembering your
      preferences and various settings.
    </p>
    <h4>How We Use Cookies</h4>
    <p>
      When you use and access the Service, we may place a number of cookies
      files in your web browser. We use cookies for the following purposes:
    </p>
    <ul>
      <li>
        <strong>Essential Cookies:</strong> We use cookies to remember
        information that changes the way the Service behaves or looks, such as a
        user's language preference on the Service.
      </li>
      <li>
        <strong>Account Cookies:</strong> We use cookies to manage the signup
        process and general administration. These cookies will usually be
        deleted when you log out; however, in some cases, they may remain
        afterward to remember your site preferences when logged out.
      </li>
      <li>
        <strong>Analytics Cookies:</strong> We use cookies to help us analyze
        how our visitors use the website and to monitor website performance.
        This helps us provide a high-quality experience by customizing our
        offering and quickly identifying and fixing any issues that arise.
      </li>
    </ul>
    <h4>Third-Party Cookies</h4>
    <p>
      In addition to our own cookies, we may also use various third-party
      cookies to report usage statistics of the Service, deliver advertisements
      on and through the Service, and so on.
    </p>
    <h4>Your Choices Regarding Cookies</h4>
    <p>
      If you'd prefer to avoid the use of cookies on the website, you must first
      disable the use of cookies in your browser and then delete the cookies
      saved in your browser associated with this website. You may use this
      option for preventing the use of cookies at any time.
    </p>
    <p>
      If you'd like to delete cookies or instruct your web browser to delete or
      refuse cookies, please visit the help pages of your web browser:
    </p>
    <p>
      If you'd like to delete cookies or instruct your web browser to delete or
      refuse cookies, please visit the help pages of your web browser:
    </p>
    <ul>
      <li>
        <a
          href="https://support.google.com/chrome/answer/95647"
          target="_blank"
          rel="noopener noreferrer"
        >
          For Chrome, visit this page from Google
        </a>
      </li>
      <li>
        <a
          href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences"
          target="_blank"
          rel="noopener noreferrer"
        >
          For Firefox, visit this page from Mozilla
        </a>
      </li>
      <li>
        <a
          href="https://support.apple.com/en-us/HT201265"
          target="_blank"
          rel="noopener noreferrer"
        >
          For Safari, visit this page from Apple
        </a>
      </li>
      <li>
        <a
          href="https://support.microsoft.com/en-us/help/278835/how-to-delete-cookie-files-in-internet-explorer"
          target="_blank"
          rel="noopener noreferrer"
        >
          For Internet Explorer, visit this page from Microsoft
        </a>
      </li>
      <li>
        <a
          href="https://support.microsoft.com/en-us/help/4027947/windows-delete-cookies"
          target="_blank"
          rel="noopener noreferrer"
        >
          For Microsoft Edge, visit this page from Microsoft
        </a>
      </li>
    </ul>
    <p>
      Please note that if you delete cookies or refuse to accept them, you might
      not be able to use all of the features we offer, you may not be able to
      store your preferences, and some of our pages might not display properly.
    </p>
    <h4>More Information About Cookies</h4>
    <p>
      You can learn more about cookies and the following third-party websites:
    </p>
    <ul>
      <li>
        <a
          href="https://www.allaboutcookies.org/"
          target="_blank"
          rel="noopener noreferrer"
        >
          AllAboutCookies
        </a>
      </li>
      <li>
        <a
          href="https://www.networkadvertising.org/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Network Advertising Initiative
        </a>
      </li>
    </ul>

    <h4>Contact Us</h4>
    <p>
      If you have any questions about our Cookies Policy, please contact us:
    </p>
    <p>Email: dev.mohamed.ali1@gmail.com</p>
    <p>Address: W2 - Westminster London</p>
    <p>Phone: +44 7770799878</p>
  </div>
);

export default CookiesPolicy;
