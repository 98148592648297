import React from "react";
import "./toggle.css";
import { Icon } from "@iconify/react/dist/iconify.js";
import { themeContext } from "../../Context/Context";
import { useContext } from "react";

const Toggle = () => {
  const theme = useContext(themeContext);
  const darkMode = theme.state.darkMode;

  const handleClick = () => {
    theme.dispatch({ type: "toggle" });
  };
  return (
    <div className="toggle" onClick={handleClick}>
      <Icon icon="line-md:moon-filled-loop" width="1rem" height="1rem" />
      <Icon icon="heroicons-solid:sun" width="1rem" height="1rem" />
      <div
        className="t-button"
        style={darkMode ? { left: "2px" } : { right: "2px" }}
      ></div>
    </div>
  );
};

export default Toggle;
