import React from "react";
import "./work.css";
import w1 from "../../img/w1.png";
import webDD from "../../img/webDD.png";
import webD from "../../img/webD.png";
import w3 from "../../img/w3.png";
import o from "../../img/o.png";
import { themeContext } from "../../Context/Context";
import { useContext } from "react";
import { motion } from "framer-motion";

const Work = () => {
  const theme = useContext(themeContext);
  const darkMode = theme.state.darkMode;
  const transition = { duration: 2, type: "spring" };

  return (
    <div className="work">
      {/* left side */}
      <motion.div
        initial={{ left: "-30%" }}
        whileInView={{ left: "0%" }}
        transition={transition}
        className="left"
      >
        <span
          style={{
            color: darkMode ? "white" : "",
          }}
        >
          Development
        </span>{" "}
        <br />
        <span>for Brands & Clients</span>
        <br />
        <span
          className="workText"
          style={{
            color: darkMode ? "white" : "",
          }}
        >
          Tailored solutions that meet your unique business needs with Custom
          Web Development, Responsive & Mobile-First Design Optimized for
          seamless performance across all devices, Performance Optimization,
          Secure & Scalable Solutions, UI/UX Design intuitive interfaces that
          engage users and drive conversions, Full-Stack Web Development
          powerful, user-friendly apps to enhance your digital presence, API
          Integration Connecting your website with third-party services for
          extended functionality, Maintenance & Support, SEO Best Practices,
          eCommerce Development
        </span>
      </motion.div>
      {/* right side */}
      <div className="w-right">
        <motion.div
          initial={{ rotate: 45 }}
          whileInView={{ rotate: 0 }}
          viewport={{ margin: "-40px" }}
          transition={{ duration: 3.5, type: "spring" }}
          className="w-mainCircle"
          style={{
            background: darkMode ? "transparent" : "",
          }}
        >
          <div
            className="w-secCircle"
            style={{
              background: darkMode ? "transparent" : "",
            }}
          >
            <img src={w1} alt="" />
          </div>
          <div
            className="w-secCircle3"
            style={{
              background: darkMode ? "transparent" : "",
            }}
          >
            <img
              src={w3}
              alt=""
              style={{
                background: darkMode ? "transparent" : "",
              }}
            />
          </div>
          <div
            className="w-secCircle2"
            style={{
              background: darkMode ? "transparent" : "",
            }}
          >
            <img src={o} alt="" />
          </div>
          <div
            className="w-secCircle"
            style={{
              background: darkMode ? "transparent" : "",
            }}
          >
            <img src={webDD} alt="" />
          </div>

          <div
            className="w-secCircle"
            style={{
              background: darkMode ? "transparent" : "",
            }}
          >
            <img src={webD} alt="" />
          </div>
        </motion.div>
        {/* background Circle*/}
        <div className="w-backCircle blueCircle"></div>
      </div>
    </div>
  );
};

export default Work;
