import React, { useEffect } from "react";
import "./intro.css";
import github from "../../img/github.png";
import linkedin from "../../img/linkedin.png";
import instagram from "../../img/instagram.png";
import blob2 from "../../img/blob2.png";
import v2 from "../../img/v2.png";
import blob6 from "../../img/blob6.png";
import ll from "../../img/ll.png";
import lll from "../../img/lll.png";
import ppt from "../../img/ppt.png";
import { themeContext } from "../../Context/Context";
import { useContext } from "react";
import { motion } from "framer-motion";

const Intro = () => {
  const transition = { duration: 2, type: "spring" };
  const theme = useContext(themeContext);
  const darkMode = theme.state.darkMode;
  useEffect(() => {
    const images = document.querySelectorAll('.portfolio-img');
    images.forEach(img => {
      img.addEventListener('load', () => {
        img.classList.add('loaded');
      });
    });
  }, []);
  return (
    <div className="intro">
      <motion.div
        className="i-left"
        initial={{ left: "-30%" }}
        whileInView={{ left: "0%" }}
        transition={transition}
      >
        <div className="i-name">
          <span>Mohamed Ali</span>
          <span
            className="introDetails"
            style={{
              color: darkMode ? "white" : "",
            }}
          >
            Creating high-performance, secure, and meticulously designed
            websites, emphasizing modern web technologies, industry best
            practices, and innovative solutions to deliver exceptional user
            experiences
          </span>
        </div>
        <div className="i-icons">
          <a href="https://github.com/devMoAli">
            <img src={github} alt="" />
          </a>
          <a href="https://linkedin.com/in/devMoAli">
            <img src={linkedin} alt="" />
          </a>
          <a href="https://instagram.com/devMoAli">
            <img src={instagram} alt="" />
          </a>
        </div>
      </motion.div>

      <div className="i-right">
        <div className="blob-container">
          <div className="blob-main">
            <img src={blob2} alt="Main Blob" className="main-blob" />
            <img src={ppt} alt="profilePic" className="profilePic portfolio-img" loading="lazy"/>
            <img src={blob6} alt="Blob 6" className="small-blob" />
          </div>
          {/* Floating Divs */}
          <motion.div
            initial={{ top: "18%", right: "-30%" }}
            whileInView={{ top: "18%", right: "0%" }}
            transition={transition}
            className="floating-developer"
          >
            <div className="floating-container">
              <img src={ll} alt="Developer Icon" />
              <div className="floating-text">
                <span>Web</span>
                <span>Developer</span>
              </div>
            </div>
            <div className="floating-blur1"></div>
          </motion.div>

          <motion.div
            initial={{ top: "30%", left: "-30%" }}
            whileInView={{ top: "30%", left: "0%" }}
            transition={transition}
            className="floating-technology"
          >
            <div className="floating-container">
              <img src={v2} alt="Technology Icon" />
            </div>
          </motion.div>

          <motion.div
            initial={{ bottom: "15%", left: "-30%" }}
            whileInView={{ bottom: "15%", left: "0%" }}
            transition={transition}
            className="floating-performance"
          >
            <div className="floating-container">
              <img src={lll} alt="Performance Icon" />
              <div className="floating-text">
                <span>Best</span>
                <span>Performance</span>
              </div>
            </div>
            <div className="floating-blur"></div>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default Intro;
