import React from 'react';

const TermsOfService = () => (
  <div>
    <h3>Terms of Service</h3>
    <p>Welcome to our service. By using our service, you agree to the following terms...</p>
    <h4>Acceptance of Terms</h4>
      <p>By accessing or using the website, you agree to be bound by these terms and conditions and our Privacy Policy. If you do not agree with these terms, you must not use our website.</p>
      <h4>Intellectual Property Rights</h4>
      <p>Other than the content you own, under these Terms, we and/or our licensors own all the intellectual property rights and materials contained in this Website. You are granted limited license only for purposes of viewing the material contained on this Website.</p>
      <h4>Restrictions</h4>
      <p>You are specifically restricted from all of the following:</p>
      <ul>
        <li>Publishing any Website material in any other media.</li>
        <li>Selling, sublicensing, and/or otherwise commercializing any Website material.</li>
        <li>Publicly performing and/or showing any Website material.</li>
        <li>Using this Website in any way that is or may be damaging to this Website.</li>
        <li>Using this Website in any way that impacts user access to this Website.</li>
        <li>Using this Website contrary to applicable laws and regulations, or in any way may cause harm to the Website, or to any person or business entity.</li>
      </ul>
      <h4>Limitation of Liability</h4>
      <p>In no event shall we, nor any of our officers, directors, and employees, be held liable to you for anything arising out of or in any way connected with your use of this Website, whether such liability is under contract, tort or otherwise, and we, including our officers, directors, and employees, shall not be held liable for any indirect, consequential, or special liability arising out of or in any way related to your use of this Website.</p>
      <h4>Indemnification</h4>
      <p>You hereby indemnify to the fullest extent possible us from and against any and/or all liabilities, costs, demands, causes of action, damages and expenses arising in any way related to your breach of any of the provisions of these Terms.</p>
      <h4>Severability</h4>
      <p>If any provision of these Terms is found to be invalid under any applicable law, such provisions shall be deleted without affecting the remaining provisions herein.</p>
      <h4>Variation of Terms</h4>
      <p>We are permitted to revise these Terms at any time as we see fit, and by using this Website you are expected to review such Terms on a regular basis to ensure you understand all terms and conditions governing use of this Website.</p>
      <h4>Assignment</h4>
      <p>We may assign, transfer, and sub-contract our rights and/or obligations under these Terms without any notification. However, you may not assign, transfer, or sub-contract any of your rights and/or obligations under these Terms.</p>
      <h4>Entire Agreement</h4>
      <p>These Terms, including any legal notices and disclaimers contained on this Website, constitute the entire agreement between us in relation to your use of this Website, and supersede all prior agreements and understandings with respect to the same.</p>
      <h4>Contact Us</h4>
      <p>If you have any questions about these Terms, please contact us:</p>
      <p>Email: dev.mohamed.ali1@gmail.com</p>
      <p>Address: W2 - Westminster London</p>
      <p>Phone: +44 7770799878</p>
  </div>
);

export default TermsOfService;
