import React, { useState } from "react";
import "./navbar.css";
import Toggle from "../Toggle/Toggle";
import { Icon } from "@iconify/react/dist/iconify.js";

const handleScroll = (event, id) => {
  event.preventDefault();
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({ behavior: 'smooth' });
  }
};

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div className="n-wrapper">
      <div className="n-left">
        <div className="n-name">Portfolio</div>
        <Toggle />
      </div>

      <div className={`n-center ${isMenuOpen ? "open" : ""}`}>
        <ul>
          <li>
            <a href="#intro" onClick={(e) => handleScroll(e, "intro")}>
              Home
            </a>
          </li>
          <li>
            <a href="#services" onClick={(e) => handleScroll(e, "services")}>
              Services
            </a>
          </li>
          <li>
            <a
              href="#experience"
              onClick={(e) => handleScroll(e, "experience")}
            >
              Experience
            </a>
          </li>
          <li>
            <a href="#portfolio" onClick={(e) => handleScroll(e, "portfolio")}>
              Projects
            </a>
          </li>
          <li>
            <a
              href="#testimonials"
              onClick={(e) => handleScroll(e, "testimonials")}
            >
              Testimonials
            </a>
          </li>
        </ul>
      </div>

      <div className="hamburger" onClick={toggleMenu}>
        <Icon
          icon="solar:hamburger-menu-bold-duotone"
          width="1.5rem"
          height="1.5rem"
        />
      </div>

      <div className="n-right">
        <button
          className="contactBtn"
          onClick={(e) => handleScroll(e, "contact")}
        >
          Contact
        </button>
      </div>
    </div>
  );
};

export default Navbar;
