// import React, { useState } from "react";
// import "./experience.css";
// import { themeContext } from "../../Context/Context";
// import { useContext } from "react";
// import { motion } from "framer-motion";
// import a from "../../img/a.png";

// const Experience = () => {
//   const Achievement = ({ finalNumber, label1, label2 }) => {
//     const [number, setNumber] = useState(0);
//     const theme = useContext(themeContext);
//     const darkMode = theme.state.darkMode;

//     const startCounting = () => {
//       let start = 0;
//       const end = parseInt(finalNumber, 10);
//       if (start === end) return;

//       const totalDuration = 2000;
//       const incrementTime = Math.abs(Math.floor(totalDuration / end));

//       const timer = setInterval(() => {
//         start += 1;
//         setNumber(start);
//         if (start === end) clearInterval(timer);
//       }, incrementTime);
//     };

//     return (
//       <motion.div
//         className="achievement"
//         initial={{ opacity: 0, y: -50 }}
//         whileInView={{ opacity: 1, y: 0 }}
//         viewport={{ once: false, amount: 1 }}
//         transition={{ duration: 2, type: "spring", stiffness: 200 }}
//         onViewportEnter={() => startCounting()}
//       >
//         <div className="circle">{number}+</div>
//         <span
//           style={{
//             color: darkMode ? "white" : "",
//           }}
//         >
//           {label1}
//         </span>
//         <span>{label2}</span>
//       </motion.div>
//     );
//   };

//   return (
//     <div className="experience">
//       <motion.div
//         className="leftE"
//         initial={{ x: "-100%", opacity: 0 }}
//         animate={{ x: 0, opacity: 1 }}
//         transition={{ type: "spring", stiffness: 100, duration: 1.5 }}
//       >
//         <Achievement finalNumber="8" label1="Years" label2="Experience" />
//         <Achievement finalNumber="500" label1="Projects" label2="Completed" />
//         <Achievement finalNumber="6" label1="Companies" label2="Worked with" />
//         <Achievement finalNumber="300" label1="Clients" label2="Dealt with" />
//       </motion.div>

//       <motion.div
//         className="rightE"
//         initial={{ x: "100%", opacity: 0 }}
//         animate={{ x: 0, opacity: 1 }}
//         viewport={{ once: false, amount: 0.5 }}
//         transition={{
//           type: "spring",
//           stiffness: 100,
//           duration: 2,
//           ease: "easeInOut",
//         }}
//       >
//         <img src={a} alt="" />
//       </motion.div>
//     </div>
//   );
// };

// export default Experience;


import React, { useState, useEffect } from "react";
import "./experience.css";
import { themeContext } from "../../Context/Context";
import { useContext } from "react";
import { motion } from "framer-motion";
import a from "../../img/a.png";

const Experience = () => {
  const Achievement = ({ finalNumber, label1, label2 }) => {
    const [number, setNumber] = useState(0);
    const [hasCounted, setHasCounted] = useState(false);
    const theme = useContext(themeContext);
    const darkMode = theme.state.darkMode;

    const startCounting = () => {
      if (hasCounted) return; // Prevent multiple count starts

      let start = 0;
      const end = parseInt(finalNumber, 10);

      const totalDuration = 2000;
      const incrementTime = Math.abs(Math.floor(totalDuration / end));

      const timer = setInterval(() => {
        start += 1;
        setNumber(start);
        if (start === end) {
          clearInterval(timer);
          setHasCounted(true); // Mark counting as completed
        }
      }, incrementTime);
    };

    return (
      <motion.div
        className="achievement"
        initial={{ opacity: 0, y: -20 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: false, amount: 0.8 }}
        transition={{ duration: 2, type: "spring", stiffness: 120  }}
        onViewportEnter={() => startCounting()}
      >
        <div className="circle">{number}+</div>
        <span
          style={{
            color: darkMode ? "white" : "",
          }}
        >
          {label1}
        </span>
        <span>{label2}</span>
      </motion.div>
    );
  };

  return (
    <div className="experience">
      <motion.div
        className="leftE"
        initial={{ x: "-100%", opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        transition={{ type: "spring", stiffness: 100, duration: 1.5 }}
      >
        <Achievement finalNumber="8" label1="Years" label2="Experience" />
        <Achievement finalNumber="500" label1="Projects" label2="Completed" />
        <Achievement finalNumber="6" label1="Companies" label2="Worked with" />
        <Achievement finalNumber="300" label1="Clients" label2="Dealt with" />
      </motion.div>

      <motion.div
        className="rightE"
        initial={{ x: "100%", opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        viewport={{ once: false, amount: 0.5 }}
        transition={{
          type: "spring",
          stiffness: 100,
          duration: 2,
          ease: "easeInOut",
        }}
      >
        <img src={a} alt="" />
      </motion.div>
    </div>
  );
};

export default Experience;
