import React from "react";
import "./popup.css";
import email2 from "../../img/email2.jpg";
import { Icon } from "@iconify/react/dist/iconify.js";

const Popup = ({ message, onClose }) => {
  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <img src={email2} alt="Email Icon" className="popup-image" />
        <p className="popup-message">
          {message}
          <Icon
            icon="openmoji:man-technologist-light-skin-tone"
            width="2rem"
            height="2rem"
          />
        </p>
        <button onClick={onClose} className="popup-close-button">
          Done
        </button>
      </div>
    </div>
  );
};

export default Popup;
