import React, { useEffect } from "react";
import "./portfolio.css";
import "swiper/css";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import { useContext } from "react";
import { themeContext } from "../../Context/Context";
import { motion } from "framer-motion";
// GIFs
import Amazon1 from "../../img/Gifs/Amazon/Amazon1.gif";
import Amazon2 from "../../img/Gifs/Amazon/Amazon2.gif";
import Amazon3 from "../../img/Gifs/Amazon/Amazon3.gif";
import Amazon4 from "../../img/Gifs/Amazon/Amazon4.gif";
import Amazon5 from "../../img/Gifs/Amazon/Amazon5.gif";
import Amazon6 from "../../img/Gifs/Amazon/Amazon6.gif";
import Amazon7 from "../../img/Gifs/Amazon/Amazon7.gif";
import Amazon8 from "../../img/Gifs/Amazon/Amazon8.gif";
import Booking0 from "../../img/Gifs/Booking/Booking0.gif";
import Booking1 from "../../img/Gifs/Booking/Booking1.gif";
import Booking2 from "../../img/Gifs/Booking/Booking2.gif";
import Booking3 from "../../img/Gifs/Booking/Booking3.gif";
import Booking4 from "../../img/Gifs/Booking/Booking4.gif";
import Booking5 from "../../img/Gifs/Booking/Booking5.gif";
import Booking6 from "../../img/Gifs/Booking/Booking6.gif";
import ChatProject1 from "../../img/Gifs/Chat/ChatProject1.gif";
import ChatProject2 from "../../img/Gifs/Chat/ChatProject2.gif";
import ChatProject3 from "../../img/Gifs/Chat/ChatProject3.gif";
import ChatProject4 from "../../img/Gifs/Chat/ChatProject4.gif";
import Calendar1 from "../../img/Gifs/Calendar/Calendar1.gif";
import Calendar2 from "../../img/Gifs/Calendar/Calendar2.gif";
import NotesProject1 from "../../img/Gifs/Notes/NotesProject1.gif";
import NotesProject2 from "../../img/Gifs/Notes/NotesProject2.gif";
import NotesProject3 from "../../img/Gifs/Notes/NotesProject3.gif";
import Restaurant from "../../img/Gifs/Restaurant/Restaurant.gif";
import QR from "../../img/Gifs/QR/QR.gif";
import movies from "../../img/Gifs/Movies/movies.png";
import Expenses from "../../img/Gifs/Expenses/Expenses.gif";
import Todo from "../../img/Gifs/todo/Todo.gif";
import Food1 from "../../img/Gifs/food/Food1.gif";
import Food2 from "../../img/Gifs/food/Food2.gif";
import blog from "../../img/Gifs/Blog/blog.gif";
import blog2 from "../../img/Gifs/Blog/blog2.gif";
import blog3 from "../../img/Gifs/Blog/blog3.gif";
import memeGenerator from "../../img/Gifs/meme/memeGenerator.gif";
import airbnb1 from "../../img/Gifs/airbnb/airbnb1.jpg";
import airbnb2 from "../../img/Gifs/airbnb/airbnb2.jpg";
// Gifs Covers
import airbnbCover from "../../img/GifsCover/airbnbCover.jpg";
import amazonCover from "../../img/GifsCover/amazonCover.jpg";
import blogCover from "../../img/GifsCover/blogCover.jpg";
import bookingCover from "../../img/GifsCover/bookingCover.jpg";
import calendarCover from "../../img/GifsCover/calendarCover.jpg";
import chatCover from "../../img/GifsCover/chatCover.jpg";
import expenseCover from "../../img/GifsCover/expenseCover.jpg";
import fooddeliveryCover from "../../img/GifsCover/fooddeliveryCover.jpg";
import memeCover from "../../img/GifsCover/memeCover.jpg";
import movieCover from "../../img/GifsCover/movieCover.jpg";
import notesCover from "../../img/GifsCover/notesCover.png";
import qrcodeCover from "../../img/GifsCover/qrcodeCover.jpg";
import todoCover from "../../img/GifsCover/todoCover.jpg";
import restCover from "../../img/GifsCover/restCover.jpg";

const Portfolio = () => {
  const theme = useContext(themeContext);
  const darkMode = theme.state.darkMode;
  const transition = { duration: 2, type: "spring" };
  useEffect(() => {
    const images = document.querySelectorAll(".lazyImg");
    images.forEach((img) => {
      img.addEventListener("load", () => {
        img.classList.add("loaded");
      });
    });
  }, []);
  return (
    <div className="portfolio">
      <motion.div
        className="portfolio-heading"
        initial={{ left: "-30%" }}
        whileInView={{ left: "0%" }}
        transition={transition}
      >
        <span
          style={{ color: darkMode ? "white" : "rgb(33, 130, 179)" }}
          className="p-heading"
        >
          Recent Projects
        </span>
        <br />
        <span
          className="projectDetails"
          style={{ color: darkMode ? "orange" : "" }}
        >
          Check out my recent projects that might meet your business needs
        </span>
      </motion.div>
      <div className="portfolio-cards">
        {/* Project 1 */}
        <div className="portfolio-card">
          <a href="https://github.com/devMoAli/Amazon-Mern-Stack-E-Commerce-Project">
            <h3  style={{ color: darkMode ? "white" : "rgb(33, 130, 179)" }} className="projectTitle">E-Commerce Project</h3>
          </a>
          <Swiper
            pagination={{ clickable: true }}
            slidesPerView={1}
            modules={[Pagination]}
            className="nested-swiper"
          >
            <SwiperSlide>
              <img
                className="lazyImg"
                loading="lazy"
                src={amazonCover}
                alt="Project 1 Gif Cover"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                className="lazyImg"
                loading="lazy"
                src={Amazon1}
                alt="Project 1 Gif 1"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                className="lazyImg"
                loading="lazy"
                src={Amazon2}
                alt="Project 1 Gif 2"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                className="lazyImg"
                loading="lazy"
                src={Amazon3}
                alt="Project 1 Gif 3"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                className="lazyImg"
                loading="lazy"
                src={Amazon4}
                alt="Project 1 Gif 4"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                className="lazyImg"
                loading="lazy"
                src={Amazon5}
                alt="Project 1 Gif 5"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                className="lazyImg"
                loading="lazy"
                src={Amazon6}
                alt="Project 1 Gif 6"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                className="lazyImg"
                loading="lazy"
                src={Amazon7}
                alt="Project 1 Gif 7"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                className="lazyImg"
                loading="lazy"
                src={Amazon8}
                alt="Project 1 Gif 8"
              />
            </SwiperSlide>
          </Swiper>
        </div>
        {/* Project 2 */}
        <div className="portfolio-card">
          <a href="https://github.com/devMoAli/Booking-Mern-Stack-Project">
            <h3  style={{ color: darkMode ? "white" : "rgb(33, 130, 179)" }} className="projectTitle">Booking Project</h3>
          </a>
          <Swiper
            pagination={{ clickable: true }}
            slidesPerView={1}
            modules={[Pagination]}
            className="nested-swiper"
          >
            <SwiperSlide>
              <img
                className="lazyImg"
                loading="lazy"
                src={bookingCover}
                alt="Project 1 Gif Cover"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                className="lazyImg"
                loading="lazy"
                src={Booking0}
                alt="Project 2 Gif 0"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                className="lazyImg"
                loading="lazy"
                src={Booking1}
                alt="Project 2 Gif 1"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                className="lazyImg"
                loading="lazy"
                src={Booking2}
                alt="Project 2 Gif 2"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                className="lazyImg"
                loading="lazy"
                src={Booking3}
                alt="Project 2 Gif 3"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                className="lazyImg"
                loading="lazy"
                src={Booking4}
                alt="Project 2 Gif 4"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                className="lazyImg"
                loading="lazy"
                src={Booking5}
                alt="Project 2 Gif 5"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                className="lazyImg"
                loading="lazy"
                src={Booking6}
                alt="Project 2 Gif 6"
              />
            </SwiperSlide>
          </Swiper>
        </div>
        {/* Project 3 */}
        <div className="portfolio-card">
          <a href="https://github.com/devMoAli/Chat_Mern_Stack_Project">
            <h3  style={{ color: darkMode ? "white" : "rgb(33, 130, 179)" }} className="projectTitle">Chat Project</h3>
          </a>
          <Swiper
            pagination={{ clickable: true }}
            slidesPerView={1}
            modules={[Pagination]}
            className="nested-swiper"
          >
            <SwiperSlide>
              <img
                className="lazyImg"
                loading="lazy"
                src={chatCover}
                alt="Project 3 Gif Cover"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                className="lazyImg"
                loading="lazy"
                src={ChatProject1}
                alt="Project 3 Gif 1"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                className="lazyImg"
                loading="lazy"
                src={ChatProject2}
                alt="Project 3 Gif 2"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                className="lazyImg"
                loading="lazy"
                src={ChatProject3}
                alt="Project 3 Gif 3"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                className="lazyImg"
                loading="lazy"
                src={ChatProject4}
                alt="Project 3 Gif 4"
              />
            </SwiperSlide>
          </Swiper>
        </div>
        {/* Project 4 */}
        <div className="portfolio-card">
          <a href="https://github.com/devMoAli/Google-Calendar-Events-Project">
            <h3  style={{ color: darkMode ? "white" : "rgb(33, 130, 179)" }} className="projectTitle">Calendar Events Project</h3>
          </a>
          <Swiper
            pagination={{ clickable: true }}
            slidesPerView={1}
            modules={[Pagination]}
            className="nested-swiper"
          >
            <SwiperSlide>
              <img
                className="lazyImg"
                loading="lazy"
                src={calendarCover}
                alt="Project 3 Gif Cover"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                className="lazyImg"
                loading="lazy"
                src={Calendar1}
                alt="Project 4 Gif 1"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                className="lazyImg"
                loading="lazy"
                src={Calendar2}
                alt="Project 4 Gif 2"
              />
            </SwiperSlide>
          </Swiper>
        </div>
        {/* Project 5 */}
        <div className="portfolio-card">
          <a href="https://github.com/devMoAli/Notes-Mern-Stack-Project">
            <h3  style={{ color: darkMode ? "white" : "rgb(33, 130, 179)" }} className="projectTitle">Notes Project</h3>
          </a>
          <Swiper
            pagination={{ clickable: true }}
            slidesPerView={1}
            modules={[Pagination]}
            className="nested-swiper"
          >
            <SwiperSlide>
              <img
                className="lazyImg"
                loading="lazy"
                src={notesCover}
                alt="Project 3 Gif Cover"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                className="lazyImg"
                loading="lazy"
                src={NotesProject1}
                alt="Project 5 Gif 1"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                className="lazyImg"
                loading="lazy"
                src={NotesProject2}
                alt="Project 5 Gif 2"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                className="lazyImg"
                loading="lazy"
                src={NotesProject3}
                alt="Project 5 Gif 3"
              />
            </SwiperSlide>
          </Swiper>
        </div>
        {/* Project 6 */}
        <div className="portfolio-card">
          <a href="https://github.com/devMoAli/Restaurant-Mern-Stack-Project">
            <h3  style={{ color: darkMode ? "white" : "rgb(33, 130, 179)" }} className="projectTitle">Restaurant Project</h3>
          </a>
          <Swiper
            pagination={{ clickable: true }}
            slidesPerView={1}
            modules={[Pagination]}
            className="nested-swiper"
          >
            <SwiperSlide>
              <img
                className="lazyImg"
                loading="lazy"
                src={restCover}
                alt="Project 3 Gif Cover"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                className="lazyImg"
                loading="lazy"
                src={Restaurant}
                alt="Project 6 Gif 1"
              />
            </SwiperSlide>
          </Swiper>
        </div>
        {/* Project 7 */}
        <div className="portfolio-card">
          <a href="https://github.com/devMoAli/QR-Code-Generator-Project">
            <h3  style={{ color: darkMode ? "white" : "rgb(33, 130, 179)" }} className="projectTitle">QR Code Generator</h3>
          </a>
          <Swiper
            pagination={{ clickable: true }}
            slidesPerView={1}
            modules={[Pagination]}
            className="nested-swiper"
          >
            <SwiperSlide>
              <img
                className="lazyImg"
                loading="lazy"
                src={qrcodeCover}
                alt="Project 3 Gif Cover"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                className="lazyImg"
                loading="lazy"
                src={QR}
                alt="Project 7 Gif 1"
              />
            </SwiperSlide>
          </Swiper>
        </div>
        {/* Project 8 */}
        <div className="portfolio-card">
          <a href="https://github.com/devMoAli/Movies-Database-Project">
            <h3  style={{ color: darkMode ? "white" : "rgb(33, 130, 179)" }} className="projectTitle">Movies Database</h3>
          </a>
          <Swiper
            pagination={{ clickable: true }}
            slidesPerView={1}
            modules={[Pagination]}
            className="nested-swiper"
          >
            <SwiperSlide>
              <img
                className="lazyImg"
                loading="lazy"
                src={movieCover}
                alt="Project 3 Gif Cover"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                className="lazyImg"
                loading="lazy"
                src={movies}
                alt="Project 8 Gif 1"
              />
            </SwiperSlide>
          </Swiper>
        </div>
        {/* Project 9 */}
        <div className="portfolio-card">
          <a href="https://github.com/devMoAli/Expense-Tracker-Project">
            <h3  style={{ color: darkMode ? "white" : "rgb(33, 130, 179)" }} className="projectTitle">Expense Tracker</h3>
          </a>
          <Swiper
            pagination={{ clickable: true }}
            slidesPerView={1}
            modules={[Pagination]}
            className="nested-swiper"
          >
            <SwiperSlide>
              <img
                className="lazyImg"
                loading="lazy"
                src={expenseCover}
                alt="Project 3 Gif Cover"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                className="lazyImg"
                loading="lazy"
                src={Expenses}
                alt="Project 9 Gif 1"
              />
            </SwiperSlide>
          </Swiper>
        </div>
        {/* Project 10 */}
        <div className="portfolio-card">
          <a href="https://github.com/devMoAli/todo-app-MERN">
            <h3  style={{ color: darkMode ? "white" : "rgb(33, 130, 179)" }} className="projectTitle">Todo List</h3>
          </a>
          <Swiper
            pagination={{ clickable: true }}
            slidesPerView={1}
            modules={[Pagination]}
            className="nested-swiper"
          >
            {" "}
            <SwiperSlide>
              <img
                className="lazyImg"
                loading="lazy"
                src={todoCover}
                alt="Project 3 Gif Cover"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                className="lazyImg"
                loading="lazy"
                src={Todo}
                alt="Project 10 Gif 1"
              />
            </SwiperSlide>
          </Swiper>
        </div>
        {/* Project 11 */}
        <div className="portfolio-card">
          <a href="https://github.com/devMoAli/food-delivery-MERN">
            <h3  style={{ color: darkMode ? "white" : "rgb(33, 130, 179)" }} className="projectTitle">Food Delivery</h3>
          </a>
          <Swiper
            pagination={{ clickable: true }}
            slidesPerView={1}
            modules={[Pagination]}
            className="nested-swiper"
          >
            <SwiperSlide>
              <img
                className="lazyImg"
                loading="lazy"
                src={fooddeliveryCover}
                alt="Project 3 Gif Cover"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                className="lazyImg"
                loading="lazy"
                src={Food1}
                alt="Project 11 Gif 1"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                className="lazyImg"
                loading="lazy"
                src={Food2}
                alt="Project 11 Gif 2"
              />
            </SwiperSlide>
          </Swiper>
        </div>
        {/* Project 12 */}
        <div className="portfolio-card">
          <a href="https://github.com/devMoAli/Blog-Mern-Stack-Project">
            <h3  style={{ color: darkMode ? "white" : "rgb(33, 130, 179)" }} className="projectTitle">Blog Project</h3>
          </a>
          <Swiper
            pagination={{ clickable: true }}
            slidesPerView={1}
            modules={[Pagination]}
            className="nested-swiper"
          >
            <SwiperSlide>
              <img
                className="lazyImg"
                loading="lazy"
                src={blogCover}
                alt="Project 3 Gif Cover"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                className="lazyImg"
                loading="lazy"
                src={blog}
                alt="Project 12 Gif 1"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                className="lazyImg"
                loading="lazy"
                src={blog2}
                alt="Project 12 Gif 2"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                className="lazyImg"
                loading="lazy"
                src={blog3}
                alt="Project 12 Gif 3"
              />
            </SwiperSlide>
          </Swiper>
        </div>
        {/* Project 13 */}
        <div className="portfolio-card">
          <a href="https://github.com/devMoAli/meme-generator">
            <h3  style={{ color: darkMode ? "white" : "rgb(33, 130, 179)" }} className="projectTitle">Meme Generator</h3>
          </a>
          <Swiper
            pagination={{ clickable: true }}
            slidesPerView={1}
            modules={[Pagination]}
            className="nested-swiper"
          >
            <SwiperSlide>
              <img
                className="lazyImg"
                loading="lazy"
                src={memeCover}
                alt="Project 3 Gif Cover"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                className="lazyImg"
                loading="lazy"
                src={memeGenerator}
                alt="Project 13 Gif 1"
              />
            </SwiperSlide>
          </Swiper>
        </div>
        {/* Project 14 */}
        <div className="portfolio-card">
          <a href="https://github.com/devMoAli/airbnb-clone">
            <h3  style={{ color: darkMode ? "white" : "rgb(33, 130, 179)" }} className="projectTitle">Airbnb Clone</h3>
          </a>
          <Swiper
            pagination={{ clickable: true }}
            slidesPerView={1}
            modules={[Pagination]}
            className="nested-swiper"
          >
            <SwiperSlide>
              <img
                className="lazyImg"
                loading="lazy"
                src={airbnbCover}
                alt="Project 3 Gif Cover"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                className="lazyImg"
                loading="lazy"
                src={airbnb1}
                alt="Project 14 Gif 1"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                className="lazyImg"
                loading="lazy"
                src={airbnb2}
                alt="Project 14 Gif 2"
              />
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default Portfolio;
