import React from "react";
import Intro from "../../Components/Intro/Intro";
import Services from "../../Components/Services/Services";
import Experience from "../../Components/Experience/Experience";
import Work from "../../Components/Work/Work";
import "./home.css";
import Portfolio from "../../Components/Portfolio/Portfolio";
import Testimonials from "../../Components/Testimonials/Testimonials";
import Contact from "../../Components/Contact/Contact";

const Home = () => {
  return (
    <div className="home">
      <section id="intro">
        <Intro />
      </section>

      <section id="services">
        <Services />
      </section>

      <section id="experience">
        <Experience />
      </section>

      <section id="portfolio">
        <Portfolio />
      </section>
      <section id="work">
        <Work />
      </section>
      <section id="testimonials">
        <Testimonials />
      </section>

      <section id="contact">
        <Contact />
      </section>
    </div>
  );
};

export default Home;
