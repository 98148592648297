import React from "react";
import "./services.css";
import v2 from "../../img/v2.png";
import v4 from "../../img/v4.png";
import e from "../../img/e.png";
import o from "../../img/o.png";
import webD from "../../img/webD.png";
import tech2 from "../../img/tech2.png";
import Card from "../Card/Card";
import MohamedAliResume from "./Mohamed Ali Resume.pdf";
import { themeContext } from "../../Context/Context";
import { useContext } from "react";
import { motion } from "framer-motion";
import { Icon } from "@iconify/react/dist/iconify.js";

const Services = () => {
  const theme = useContext(themeContext);
  const darkMode = theme.state.darkMode;
  const transition = { duration: 2, type: "spring" };

  return (
    <div className="services">
      <motion.div
        className="left"
        initial={{ left: "-30%" }}
        whileInView={{ left: "0%" }}
        transition={transition}
      >
        <span
          style={{
            color: darkMode ? "white" : "",
          }}
        >
          My Services
        </span>{" "}
        <br />
        <span>Check out the technologies used</span>
        <br />
        <span
          style={{
            color: darkMode ? "white" : "",
          }}
        >
          Custom Web Development, Responsive & Mobile-First Design, Performance
          Optimization, Secure & Scalable Solutions, UI/UX Design, Full-Stack
          Web Development, API Integration, Maintenance & Support, SEO Best
          Practices, eCommerce Development
        </span>
        <div className="buttonDiv">
          <a href={MohamedAliResume} download className="serviceBtn">
            <span>Download CV</span>
            <Icon icon="line-md:download-loop" width="1.5rem" height="1.5rem" />
          </a>{" "}
        </div>
      </motion.div>

      {/* Cards */}
      <div className="cards">
        <motion.div
          initial={{ opacity: 0.5, left: "0%", top: "0%" }}
          whileInView={{ opacity: 1, left: "0%", top: "0%" }}
          transition={transition}
          className="card-technologies"
        >
          <Card
            emoji={v4}
            heading={"Technologies"}
            details={
              "Full Stack Development, JavaScript, TypeScript, React, Next.js, REST APIs, Cloud Services"
            }
            link="https://www.simplilearn.com/top-technology-trends-and-jobs-article"
          />
        </motion.div>
        <motion.div
          initial={{ opacity: 0.5, left: "0%", top: "0%" }}
          whileInView={{ opacity: 1, left: "30%", top: "10%" }}
          transition={transition}
          className="card-performance"
        >
          <Card
            emoji={v2}
            heading={"Performance"}
            details={
              "SEO Best Practices, Performance Tuning, Integration, Deployment, Hosting"
            }
            link="https://www.techempower.com/benchmarks/#hw=ph&test=fortune&section=data-r22"
          />
        </motion.div>
        <motion.div
          initial={{ opacity: 0.5, left: "0%", top: "0%" }}
          whileInView={{ opacity: 1, left: "60%", top: "20%" }}
          transition={transition}
          className="card-security"
        >
          <Card
            emoji={tech2}
            heading={"Security"}
            details={
              "Personal Data Encryption, Cybersecurity Defense, Secure Authentication"
            }
            link="https://www.techuk.org/accelerating-innovation/cyber-security.html"
          />
        </motion.div>
        <motion.div
          initial={{ opacity: 0.5, left: "0%", top: "0%" }}
          whileInView={{ opacity: 1, left: "0%", top: "30%" }}
          transition={transition}
          className="card-speed"
        >
          <Card
            emoji={o}
            heading={"Speed Optimization"}
            details={
              "Fast Loading Times, Code Splitting, Lazy Loading, Image Optimization"
            }
            link="https://www.cloudflare.com/en-gb/learning/performance/speed-up-a-website/"
          />
        </motion.div>

        <motion.div
          initial={{ opacity: 0.5, left: "0%", top: "0%" }}
          whileInView={{ opacity: 1, left: "30%", top: "40%" }}
          transition={transition}
          className="card-efficiency"
        >
          <Card
            emoji={e}
            heading={"Efficiency"}
            details={
              "Efficient Code, Low Resource Consumption, High Uptime, Scalable Architecture"
            }
            link="https://www.economicshelp.org/blog/glossary/technical-efficiency/"
          />
        </motion.div>
        <motion.div
          initial={{ opacity: 0, left: "0%", top: "0%" }}
          whileInView={{ opacity: 1, left: "60%", top: "48%" }}
          transition={transition}
          className="card-innovation"
        >
          <Card
            emoji={webD}
            heading={"Innovation"}
            details={
              "Cutting-edge Technologies, Creative Solutions, Future-proof Design, Adaptive Strategies"
            }
            link="https://www.techuk.org/tech-and-innovation-programme.html"
          />
        </motion.div>
      </div>
    </div>
  );
};

export default Services;



// import React from "react";
// import "./services.css";
// import v2 from "../../img/v2.png";
// import v4 from "../../img/v4.png";
// import e from "../../img/e.png";
// import o from "../../img/o.png";
// import webD from "../../img/webD.png";
// import tech2 from "../../img/tech2.png";
// import MohamedAliResume from "./Mohamed Ali Resume.pdf";
// import { themeContext } from "../../Context/Context";
// import { useContext } from "react";
// import { motion } from "framer-motion";
// import { Icon } from "@iconify/react/dist/iconify.js";

// const Services = () => {
//   const theme = useContext(themeContext);
//   const darkMode = theme.state.darkMode;
//   const transition = { duration: 2, type: "spring" };

//   return (
//     <div className="services">
//       <motion.div
//         className="left"
//         initial={{ left: "-30%" }}
//         whileInView={{ left: "0%" }}
//         transition={transition}
//       >
//         <span style={{ color: darkMode ? "white" : "" }}>
//           My Services
//         </span>{" "}
//         <br />
//         <span>Check out the technologies used</span>
//         <br />
//         <span style={{ color: darkMode ? "white" : "" }}>
//           Custom Web Development, Responsive & Mobile-First Design, Performance
//           Optimization, Secure & Scalable Solutions, UI/UX Design, Full-Stack
//           Web Development, API Integration, Maintenance & Support, SEO Best
//           Practices, eCommerce Development
//         </span>
//         <div className="buttonDiv">
//           <a href={MohamedAliResume} download className="serviceBtn">
//             <span>Download CV</span>
//             <Icon icon="line-md:download-loop" width="1.5rem" height="1.5rem" />
//           </a>{" "}
//         </div>
//       </motion.div>

     
//       <div className="cards">
//         <motion.div
//           initial={{ opacity: 0.5, left: "0%", top: "0%" }}
//           whileInView={{ opacity: 1, left: "0%", top: "0%" }}
//           transition={transition}
//           className="card"
//           style={{ position: "absolute", width: "100%" }}
//         >
//           <img src={v4} alt="Technologies" />
//           <span style={{ color: darkMode ? "orange" : "" }}>
//             Technologies
//           </span>
//           <span  style={{ color: darkMode ? "white" : "" }}>
//             Full Stack Development, JavaScript, TypeScript, React, Next.js, REST APIs, Cloud Services
//           </span>
//           <a href="https://www.simplilearn.com/top-technology-trends-and-jobs-article" target="_blank" rel="noopener noreferrer">
//             <button className="c-btn" style={{ background: darkMode ? "none" : "" }}>
//               Learn More
//             </button>
//           </a>
//         </motion.div>

//         <motion.div
//           initial={{ opacity: 0.5, left: "0%", top: "0%" }}
//           whileInView={{ opacity: 1, left: "30%", top: "10%" }}
//           transition={transition}
//           className="card"
//           style={{ position: "absolute", width: "100%" }}
//         >
//           <img src={v2} alt="Performance" />
//           <span style={{ color: darkMode ? "orange" : "" }}>
//             Performance
//           </span>
//           <span style={{ color: darkMode ? "white" : "" }}>
//             SEO Best Practices, Performance Tuning, Integration, Deployment, Hosting
//           </span>
//           <a href="https://www.techempower.com/benchmarks/#hw=ph&test=fortune&section=data-r22" target="_blank" rel="noopener noreferrer">
//             <button className="c-btn" style={{ background: darkMode ? "none" : "" }}>
//               Learn More
//             </button>
//           </a>
//         </motion.div>

//         <motion.div
//           initial={{ opacity: 0.5, left: "0%", top: "0%" }}
//           whileInView={{ opacity: 1, left: "60%", top: "20%" }}
//           transition={transition}
//           className="card"
//           style={{ position: "absolute", width: "100%" }}
//         >
//           <img src={tech2} alt="Security" />
//           <span style={{ color: darkMode ? "orange" : "" }}>
//             Security
//           </span>
//           <span style={{ color: darkMode ? "white" : "" }}>
//             Personal Data Encryption, Cybersecurity Defense, Secure Authentication
//           </span>
//           <a href="https://www.techuk.org/accelerating-innovation/cyber-security.html" target="_blank" rel="noopener noreferrer">
//             <button className="c-btn" style={{ background: darkMode ? "none" : "" }}>
//               Learn More
//             </button>
//           </a>
//         </motion.div>

//         <motion.div
//           initial={{ opacity: 0.5, left: "0%", top: "0%" }}
//           whileInView={{ opacity: 1, left: "0%", top: "30%" }}
//           transition={transition}
//           className="card"
//           style={{ position: "absolute", width: "100%" }}
//         >
//           <img src={o} alt="Speed Optimization" />
//           <span style={{ color: darkMode ? "orange" : "" }}>
//             Speed Optimization
//           </span>
//           <span style={{ color: darkMode ? "white" : "" }}>
//             Fast Loading Times, Code Splitting, Lazy Loading, Image Optimization
//           </span>
//           <a href="https://www.cloudflare.com/en-gb/learning/performance/speed-up-a-website/" target="_blank" rel="noopener noreferrer">
//             <button className="c-btn" style={{ background: darkMode ? "none" : "" }}>
//               Learn More
//             </button>
//           </a>
//         </motion.div>

//         <motion.div
//           initial={{ opacity: 0.5, left: "0%", top: "0%" }}
//           whileInView={{ opacity: 1, left: "30%", top: "40%" }}
//           transition={transition}
//           className="card"
//           style={{ position: "absolute", width: "100%" }}
//         >
//           <img src={e} alt="Efficiency" />
//           <span style={{ color: darkMode ? "orange" : "" }}>
//             Efficiency
//           </span>
//           <span style={{ color: darkMode ? "white" : "" }}>
//             Efficient Code, Low Resource Consumption, High Uptime, Scalable Architecture
//           </span>
//           <a href="https://www.economicshelp.org/blog/glossary/technical-efficiency/" target="_blank" rel="noopener noreferrer">
//             <button className="c-btn" style={{ background: darkMode ? "none" : "" }}>
//               Learn More
//             </button>
//           </a>
//         </motion.div>

//         <motion.div
//           initial={{ opacity: 0, left: "0%", top: "0%" }}
//           whileInView={{ opacity: 1, left: "60%", top: "48%" }}
//           transition={transition}
//           className="card"
//           style={{ position: "absolute", width: "100%" }}
//         >
//           <img src={webD} alt="Innovation" />
//           <span style={{ color: darkMode ? "orange" : "" }}>
//             Innovation
//           </span>
//           <span style={{ color: darkMode ? "white" : "" }}>
//             Cutting-edge Technologies, Creative Solutions, Future-proof Design, Adaptive Strategies
//           </span>
//           <a href="https://www.techuk.org/tech-and-innovation-programme.html" target="_blank" rel="noopener noreferrer">
//             <button className="c-btn" style={{ background: darkMode ? "none" : "" }}>
//               Learn More
//             </button>
//           </a>
//         </motion.div>
//       </div>
//     </div>
//   );
// };

// export default Services;
