import React from "react";
import "./card.css";
import { themeContext } from "../../Context/Context";
import { useContext } from "react";

const Card = ({ emoji, heading, details, link }) => {
  const theme = useContext(themeContext);
  const darkMode = theme.state.darkMode;

  return (
    <div className="card">
      <img src={emoji} alt="" />
      <span
        style={{
          color: darkMode ? "orange" : "",
        }}
      >
        {heading}
      </span>
      <span
        style={{
          color: darkMode ? "white" : "",
        }}
      >
        {details}
      </span>
      <a href={link} target="_blank" rel="noopener noreferrer">
        <button
          className="c-btn"
          style={{
            background: darkMode ? "none" : "",
          }}
        >
          Learn More
        </button>
      </a>
    </div>
  );
};

export default Card;
